<template>
  <!-- Modal -->
  <div
    class="modal fade new-member"
    id="new-question"
    data-backdrop="static"
    role="dialog"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    @click="reset"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content radius-xl">
        <div class="modal-header">
          <h6 class="modal-title fw-500" id="staticBackdropLabel">Question Settings</h6>
          <button
            id="myCheck"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            ref="clsBtn"
          >
            <i class="las la-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <!-- <span @click="">meeta</span> -->

          <div class="new-member-modal">
            <form @submit.prevent="createQuestion">
              <div class="alert alert-danger" v-if="error.message">{{error.message}}</div>
              <div class="form-group mb-20">
                <label for="name">Description</label>
                <input
                  v-model="$v.description.$model"
                  class="form-control"
                  type="text"
                  placeholder="Enter description"
                />
                <span
                  v-if="$v.description.$error && !$v.description.required"
                  class="error"
                  >Description cannot be empty</span
                >
                <span v-if="!$v.description.minLength" class="error"
                  >Too few a description</span
                >
              </div>

              <div class="form-group mb-20">
                <div class="row">
                  <div class="col-sm-6">
                    <label for="name">Score</label>
                    <input
                      v-model="$v.score.$model"
                      class="form-control"
                      type="text"
                      placeholder="Enter score"
                    />
                    <span v-if="$v.score.$error && !$v.score.required" class="error"
                      >Score can not be empty</span
                    >
                    <span v-if="!$v.score.between" class="error"
                      >Score must be between {{ $v.score.$params.between.min }} and
                      {{ $v.score.$params.between.max }}
                    </span>
                  </div>
                  <div class="col-sm-6">
                    <label for="name47">Duration</label>
                    <input
                      v-model="$v.duration.$model"
                      type="text"
                      class="form-control"
                      placeholder="Enter duration in seconds"
                    />
                    <span v-if="$v.duration.$error" class="error"
                      >Duration cannot be empty</span
                    >
                  </div>
                </div>
              </div>
              <div class="form-group mb-20">
                <div class="row">
                  <div class="col-sm-6">
                    <label for="labelType">Label Type</label>
                    <div class="category-member">
                      <v-select
                        v-model="$v.lType.$model"
                        :options="labelTypes"
                      ></v-select>
                    </div>
                    <span v-if="$v.lType.$error" class="error">Select a label Type </span>
                  </div>
                  <div class="col-sm-6">
                    <label for="name47">Question Type</label>
                    <div class="category-member">
                      <v-select
                        v-model="$v.qType.$model"
                        :options="questionTypes"
                      ></v-select>
                    </div>
                    <span v-if="$v.qType.$error" class="error"
                      >Select a question type
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group textarea-group" id="qcheckbox">
                <label class="mb-15">Scheme</label>
                <div class="d-flex">
                  <div class="project-task-list__left d-flex align-items-center">
                    <div class="checkbox-group d-flex pr-10">
                      <div
                        class="grp-theme-default custom-checkbox checkbox-group__single d-flex"
                      >
                        <input
                          class="checkbox"
                          type="checkbox"
                          name="term"
                          id="check-grp-1"
                          :value="1"
                        />
                        <label for="check-grp-1" class="fs-14 color-light strikethrough">
                          1<sup>st</sup> Term
                        </label>
                      </div>
                    </div>
                    <div class="checkbox-group d-flex pr-10">
                      <div
                        class="checkbox-theme-default custom-checkbox checkbox-group__single d-flex"
                      >
                        <input
                          class="checkbox"
                          type="checkbox"
                          name="term"
                          id="check-grp-2"
                          :value="2"
                        />
                        <label for="check-grp-2" class="fs-14 color-light strikethrough">
                          2<sup>nd</sup> Term
                        </label>
                      </div>
                    </div>
                    <div class="checkbox-group d-flex">
                      <div
                        class="checkbox-theme-default custom-checkbox checkbox-group__single d-flex"
                      >
                        <input
                          class="checkbox"
                          type="checkbox"
                          id="check-grp-3"
                          name="term"
                          :value="3"
                        />
                        <label for="check-grp-3" class="fs-14 color-light strikethrough">
                          3<sup>rd</sup> Term
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <span v-if="$v.term.$error" class="error">Select at least one term</span>
              </div>

              <div class="form-group mb-20">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label class="mb-15"
                        >Select difficulty level
                        <small> from 1 to 10 </small>
                      </label>
                      <div class="slider-wrapper">
                        <vue-slider
                          v-model="$v.difficulty.$model"
                          :min="0"
                          :max="10"
                        ></vue-slider>
                      </div>
                    </div>
                  </div>
                </div>
                <span v-if="$v.difficulty.$error" class="error"
                  >Difficulty cannot be zero</span
                >
              </div>

              <div class="button-group d-flex pt-25">
                <button
                  type="submit"
                  class="btn btn-primary btn-default btn-squared text-capitalize"
                  :disabled="disabled"
                >
                  add Question
                </button>
                <button
                  class="btn btn-light btn-default btn-squared fw-400 text-capitalize b-light color-light"
                  data-dismiss="modal"
                  aria-label="Close"
                  :disabled="disabled"
                >
                  cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
</template>

<script>
import { required, minLength, maxLength, between } from "vuelidate/lib/validators";
import { apiClient } from "../plugins/restclient";
export default {
  data() {
    return {
      qType: "",
      lType: "",
      description: "",
      score: "",
      duration: "",
      term: [],
      difficulty: "",
      disabled: false,
      
      questionTypes: [
        {
          label: "DICHOTOMOUS",
          code: 1,
        },
        {
          label: "SIMPLE",
          code: 2,
        },
        {
          label: "MULTI",
          code: 4,
        },
        {
          label: "RANK",
          code: 8,
        },
        {
          label: "OPEN",
          code: 16,
        },
      ],
      labelTypes: [
        {
          label: "ALPHA",
          code: "alpha",
        },
        {
          label: "NUMBER",
          code: "number",
        },
        {
          label: "ROMAN",
          code: "roman",
        },
        {
          label: "BULLET",
          code: "bullet",
        },
        {
          label: "NONE",
          code: "none",
        },
      ],
      error:{
        message: ''
      }
    };
  },
  props: ["qMeta"],
  methods: {
    reset(){
      // this.qType = ""
      // this.lType = ""
      // this.description = ""
      // this.score = ""
      // this.duration = ""
      // this.term = []
      // this.difficulty = ""
    },
    createQuestion() {
      this.$v.$touch();

      let checkbox = document.querySelectorAll("#qcheckbox .checkbox");
      this.term = [];
      checkbox.forEach((item) => {
        if (item.checked) {
          this.term.push(parseInt(item.value));
        }
      });

      if (this.$v.$invalid) {
        return;
      }
      const reqBody = {
        ...this.qMeta,
        duration: parseInt(this.duration),
        score: parseInt(this.score),
        difficulty: parseInt(this.difficulty),
        label: this.lType.code,
        description: this.description,
        scheme: this.term,
        type: this.qType.code,
      };

      var ts = Math.round(+new Date() / 1000);

      const headers = {
        "X-Timestamp": ts,
        "X-Ulid": `${this.$store.state.user.ulid}`,
      };
      console.log('aaaapi before allß')
      apiClient.post("v1/office/assessment/question/add", reqBody, {
        headers: headers,
      }).then((res)=>{
        
        
        const params = {
          qlid: res.data.info.qlid,
          ulid: this.$store.getters.user.ulid,
          clsId: this.qMeta.cllid,
          tname: this.qMeta.tname
        }

        localStorage.setItem("questionMeta", JSON.stringify(params))

        this.$router.push({
          name: "classes-topics-questions-add"
        })
          this.$refs.clsBtn.click()

      })
      .catch(err=>{
        this.error.message = err.response?.data.message
      });
    },
  },
  validations: {
    description: {
      required,
      minLength: minLength(10),
    },
    score: {
      required,
      between: between(1, 10),
    },
    duration: {
      required,
    },
    lType: {
      required,
    },
    qType: {
      required,
    },
    term: {
      required,
    },
    difficulty: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(10),
    },
  },
};
</script>

<style>
.error {
  color: rgba(235, 53, 53, 0.89);
  font-size: 13px;
  border: 0;
}
</style>
