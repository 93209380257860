<template>
  <div class="d-flex justify-content-center align-items-center mt-2">
    <label for="psize mr-2" style="white-space: nowrap">Page size: </label>
    <select class="form-control ml-2" id="psize" @change="selectionChange" v-model="size">
      <option>10</option>
      <option>20</option>
      <option>50</option>
      <option>100</option>
    </select>
  </div>
</template>

<script>
export default {
    data(){
        return {
            size: 10
        }
    },
    methods:{
        selectionChange(){
            this.$emit("sizeChange", this.size )
        }
    }
};
</script>

<style>
</style>